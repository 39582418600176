import * as React from 'react'
import {
  useCMS,
  useForm,
  usePlugin,
  ActionButton,
  Modal,
  PopupModal,
  ModalHeader,
  ModalBody,
  ModalActions,
} from 'tinacms'
import { Button } from '@tinacms/styles'
import { Editor } from '../components/editor'
import { InlineForm, InlineBlocks, InlineGroup } from 'react-tinacms-inline'
import { getSectionBlocks } from '../components/section'
import { SidebarSectionBlock } from '../components/sidebarBlocks'
import { CMSWrapper } from '../components/cmsWrapper'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { ROLE_ADMIN, ROLE_EDITOR } from '../cms/security'
import { RoleCheck } from '../components/rolecheck'
import { alertError, isEmptyString } from '../cms/validation'
import {
  refreshAndRedirect,
  refreshAndRedirectHome,
  getDeleteRefreshTimeout,
  refreshSourcedContent,
} from '../cms/refresh'
import { graphql } from 'gatsby'
import fetch from 'node-fetch'
import slugify from 'slugify'

const REQUIRED_FIELD_VALIDATION_MSG = 'Dieses Feld ist ein Pflichtfeld.'

const DeleteAction = ({ form }) => {
  const cms = useCMS()
  const [showPopup, setShowPopup] = React.useState(false)
  return (
    <>
      {showPopup ? (
        <Modal>
          <PopupModal>
            <ModalHeader>Landingpage löschen</ModalHeader>
            <ModalBody padded={true}>
              Möchten Sie den Landingpage wirklich löschen?
            </ModalBody>
            <ModalActions>
              <Button onClick={() => setShowPopup(false)}>Abbrechen</Button>
              <Button
                onClick={async () => {
                  await fetch(`/_cms/landingpages/${form.values._id}`, {
                    method: 'DELETE',
                  })
                    .then(async () => {
                      refreshAndRedirectHome(getDeleteRefreshTimeout())
                      cms.alerts.success('Der Landingpage wurde gelöscht!')
                    })
                    .catch((e) => {
                      cms.alerts.error('Fehler beim Löschen des Landingpages!')
                      console.log(e)
                    })
                  setShowPopup(false)
                  window.location.href = '/'
                }}
                primary
              >
                Löschen
              </Button>
            </ModalActions>
          </PopupModal>
        </Modal>
      ) : null}
      <RoleCheck requiredRoles={[ROLE_ADMIN, ROLE_EDITOR]}>
        <ActionButton onClick={() => setShowPopup(true)}>
          Landingpage löschen
        </ActionButton>
      </RoleCheck>
    </>
  )
}

export default function Landingpage({ data, pageContext }) {
  let initialDataLoader

  const loadLandingpage = async () => {
    const landingpageResponse = await fetch(
      `/_cms/landingpages/${pageContext.id}`
    )
    return landingpageResponse.json()
  }

  if (process.env.NODE_ENV === 'production') {
    initialDataLoader = {
      initialValues: data.landingpage,
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    initialDataLoader = {
      async loadInitialValues() {
        return loadLandingpage().then((landingpage) => landingpage)
      },
    }
  }

  return (
    <LandingpageTemplate data={data} initialDataLoader={initialDataLoader} />
  )
}

function LandingpageTemplate({ data, initialDataLoader }) {
  const landingpage = data.landingpage
  const landingpageReferences = data.allReference.nodes

  const entityContext = {
    entityType: 'landingpage',
    mediaDir: landingpage.mediaDir,
  }

  const LandingpageForm = {
    title: 'Landingpage',
    actions: [DeleteAction],
    fields: [
      {
        name: 'title',
        label: 'Titel',
        component: 'text',
      },
      {
        name: 'slug',
        label: 'URL-Pfad',
        component: 'text',
        description:
          'Pfad der Seite in der URL. Warnung: Änderung nur mit Vorsicht!',
        format: (value) =>
          value &&
          slugify(value.substring(value.lastIndexOf('/') + 1).toLowerCase(), {
            locale: 'de',
          }),
        validate(title) {
          if (!title) {
            return REQUIRED_FIELD_VALIDATION_MSG
          }
        },
      },
      {
        label: 'Inhalt',
        name: 'content',
        component: 'group',
        fields: [
          {
            label: 'Sections',
            name: 'sections',
            component: 'blocks',
            templates: {
              Section: SidebarSectionBlock(entityContext),
            },
          },
        ],
      },
      {
        name: 'isDraft',
        component: 'toggle',
        label: 'Entwurf',
        description: 'Als Entwurf speichern',
      },
    ],
    onSubmit(formData) {
      // Field validation
      if (isEmptyString(formData.title)) {
        return alertError(cms, 'Der Titel darf nicht leer sein!')
      }

      cms.alerts.info('Änderungen werden gespeichert...')
      return fetch(`/_cms/landingpages/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...formData,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            cms.alerts.success('Speichern erfolgreich.')
          } else if (response.status === 401 || response.status === 403) {
            cms.alerts.error(
              'Sie haben nicht die Berechtigung, den Datensatz zu speichern!',
              5000
            )
          } else {
            response.json().then((data) => {
              console.log(
                `Updating landingpage id ${formData._id} failed: ` +
                  JSON.stringify(data)
              )
              const errorMessage = getUpdateErrorMessage(data)
              cms.alerts.error(errorMessage, 8000)
            })
          }
          return response.ok
        })
        .then(async (ok) => {
          if (ok === true) {
            if (landingpage.slug !== formData.slug) {
              await refreshAndRedirect(formData.slug, 2000)
            } else {
              await refreshSourcedContent()
            }
          }
        })
        .catch((e) => {
          cms.alerts.error('Fehler beim Speichern!')
          console.log('Updating failed: ' + e)
        })
    },
    ...initialDataLoader,
  }

  const cms = useCMS()
  const [editableData, form] = useForm(LandingpageForm)

  const formContent = form.values.content
  const formSectionsCount = formContent ? formContent.sections.length : 0

  usePlugin(form)

  return (
    <CMSWrapper>
      <GlobalHelmet title={`UKE jukebox - ${editableData.title}`} />
      <main>
        <Navigation />
        {/* Placeholder to ensure editor visibility under header when there's no content */}
        {formSectionsCount === 0 && <div style={{ height: 80 }}></div>}
        <Editor form={form}>
          <InlineForm form={form}>
            <InlineGroup
              name="content"
              focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
            >
              <InlineBlocks
                name="sections"
                blocks={getSectionBlocks(entityContext)}
                itemProps={{ entityContext: entityContext, entityForm: form }}
              />
            </InlineGroup>
          </InlineForm>
        </Editor>
        <StaticReferenceModules referencesData={landingpageReferences} />
        <Footer />
      </main>
    </CMSWrapper>
  )
}

const getUpdateErrorMessage = (jsonResponse) => {
  // ggf. detaillierteres Fehlerhandling im Backend implementieren, spezielle Fehlercodes, so dass
  // der Fehler hier nicht geparst werden muss
  const errMsg = jsonResponse.message
  if (errMsg.includes('E11000 duplicate key error')) {
    if (errMsg.includes('title:'))
      return 'Speichern nicht möglich: Der Titel wird bereits verwendet!'
    else if (errMsg.includes('slug:'))
      return 'Speichern nicht möglich: Der Pfad wird bereits verwendet!'
  } else {
    return 'Fehler beim Speichern!'
  }
}
// METAINFO
export const query = graphql`
  query LandingpageQuery($id: String) {
    landingpage(_id: { eq: $id }) {
      _id
      createdAt
      createdBy
      content {
        sections {
          grids {
            _template
            columns {
              _id
              _template
              altText
              description
              descriptionAlignment
              filename
              showFrame
              listStyle
              text
              textAlign
              url
              href
              align
              buttonBgColor
              label
              images {
                _template
                altText
                description
                filename
                showFrame
              }
            }
            itemAlignment
            marginType
          }
          _template
          bgColor
        }
      }
      mediaDir
      slug
      updatedAt
      updatedBy
      title
      isDraft
    }
    allReference(
      filter: { types: { in: "Landingpage" }, isDraft: { eq: false } }
    ) {
      nodes {
        title
        _id
        types
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _id
                _template
                altText
                description
                descriptionAlignment
                filename
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
                showFrame
                listStyle
                text
                textAlign
                url
                href
                align
                buttonBgColor
                label
              }
              itemAlignment
              marginType
            }
          }
        }
        updatedBy
        updatedAt
        createdAt
        createdBy
        mediaDir
        slug
        priority
      }
    }
  }
`
